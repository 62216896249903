import React from "react"
import { ReactSVG } from "react-svg"
import classNames from "classnames"
import {
  DEFAULT_SIZE_BUTTON,
  MAIN_BUTTON,
  SECONDARY_BUTTON,
  SMALL_SIZE_BUTTON,
  HEADER_MAIN_BUTTON,
  HEADER_SECONDARY_BUTTON,
} from "@constants"
import "../../Button.scss"

const ButtonContainer = ({
    text,
    handleClick,
    svgPath,
    type,
    size,
    isActive,
    isDisabled,
    blogMobile,
    className,
    bannerButtonIconPath,
    id,
}) => {

  const buttonClassName = classNames("button", {
    "main-button": type === MAIN_BUTTON && size !== HEADER_MAIN_BUTTON,
    "main-button--header": type === MAIN_BUTTON && size === HEADER_MAIN_BUTTON,
    "secondary-button--default":
      type === SECONDARY_BUTTON && size === DEFAULT_SIZE_BUTTON,
    "secondary-button--small":
      type === SECONDARY_BUTTON && size === SMALL_SIZE_BUTTON,
    "secondary-button--header":
      type === SECONDARY_BUTTON && size === HEADER_SECONDARY_BUTTON,
    "blog-mobile": blogMobile,
    [className]: !!className,
    isActive,
    disabled: isDisabled,
  })

  return (
    <button
      id={id}
      className={buttonClassName}
      onClick={handleClick}
      disabled={isDisabled}
      type="button"
    >
      {text}
      {bannerButtonIconPath && (
        <img
          src={bannerButtonIconPath}
          className="button__icon"
          alt="button icon"
        />
      )}
      {svgPath && (
        <ReactSVG src={svgPath} className="button__icon" alt="button icon" />
      )}
    </button>
  )
}

ButtonContainer.defaultProps = {
    text: "",
    svgPath: "",
    type: MAIN_BUTTON,
    isDisabled: false,
};

export default ButtonContainer
