import React from "react"
import Link from "../Link"
import { ButtonContainer } from "./components"

const Button = ({ href, ...props }) => {

  if (!!href) {
    return (
      <Link to={href}>
        <ButtonContainer {...props} />
      </Link>
    )
  }

  return (
    <ButtonContainer {...props} />
  )
}

export default Button;
