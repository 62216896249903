import React, { useCallback, useState } from "react";
import "./DropDownListSection.scss";
import { MAIN_BUTTON } from "@constants";
import Button from "@common/Button/Button";
import PageHeading from "@common/PageHeading/PageHeading";
import DropDownListItem from "./DropDownListItem/DropDownListItem";

const DropDownListSection = ({
    sectionItems,
    isMobile,
    heading,
    description,
    headingMargin,
    itemsMargin,
    changeTitleOnClick,
    maxItemsAmount,
}) => {
    const [isAdditionalFAQLoaded, setIsAdditionalFAQLoaded] = useState(false);

    const generateItemsByAmount = useCallback((itemsAmount, startIndex = 0) => {
        const componentElements = [];
        sectionItems.forEach((item, index) => {
            if (index < startIndex && itemsAmount) return;
            if (index >= itemsAmount && itemsAmount) return;

            const { title, path, iconPath, description, descriptionItems } = item;
            const iconPathIndex = isMobile ? 1 : 0;
            const currentImage = Array.isArray(iconPath)
                ? iconPath[iconPathIndex]
                : iconPath;

            componentElements.push(
                <DropDownListItem
                    key={`${index}+${currentImage}`}
                    title={title}
                    path={path}
                    iconPath={currentImage}
                    description={description}
                    descriptionItems={descriptionItems}
                    itemsMargin={itemsMargin}
                    changeTitleOnClick={changeTitleOnClick}
                />
            );
        });

        return componentElements;
    }, []);

    return (
        <>
            {(heading || description) && (
                <div
                    className="product-development__heading"
                    style={
                        headingMargin && {
                            marginBottom: headingMargin,
                        }
                    }
                >
                    <PageHeading
                        heading={heading}
                        mainParagraph={description}
                    />
                </div>
            )}
            <div className="development-section">
                <div className="development-section__items-container">
                    {generateItemsByAmount(maxItemsAmount)}
                    {(maxItemsAmount < sectionItems.length) && !isAdditionalFAQLoaded && (
                        <div className="development-section__button-container">
                            <Button
                                text="Load more"
                                type={MAIN_BUTTON}
                                handleClick={() => setIsAdditionalFAQLoaded(true)}
                            />
                        </div>
                    )}
                    {isAdditionalFAQLoaded && (
                        <>{generateItemsByAmount(sectionItems.length, maxItemsAmount)}</>
                    )}
                </div>
            </div>
        </>
    );
};

export default DropDownListSection;
