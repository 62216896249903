import React from "react"
import { StyledInternalLink, StyledExternalLink } from "./styled"

const Link = ({ children, to }) => {
  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <StyledInternalLink to={to}>
        {children}
      </StyledInternalLink>
    )
  }
  return (
    <StyledExternalLink href={to} target="_blank">
      {children}
    </StyledExternalLink>
  )
}

export default Link;
